@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

body, html {
  height: 100%;
  width: 100%;
  margin: 0;
}

.background {
  position: absolute;
  background-size: cover;
  height: 100%;
  width: 100%;
  margin: 0;
  background-image: url(/images/background.jpg);
}

.jumbotron {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 50px;
  text-align: left;
  background-color: #ffffff88;
  border-radius: 25px;
}

.brand {
  font-family: 'Abel';
}

.jumbotron .brand {
  font-size: 3.5rem;
}

.jumbotron .brand-com {
  font-size: 3rem;
}

.jumbotron p {
  font-size: 2rem;
}

.brand-virtual {
  color: #336e74;
}
.brand-pastries {
  color: #b5604c;
}
.brand-com {
  color: #a5abab;
}
