@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abel&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: Roboto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.spacer1 {
  margin-bottom: 10px;
}

.spacer2 {
  margin-bottom: 50px;
}
.spacer1 {
  margin-bottom: 10px;
}

.spacer2 {
  margin-bottom: 50px;
}
body, html {
  height: 100%;
  width: 100%;
  margin: 0;
}

.background {
  position: absolute;
  background-size: cover;
  height: 100%;
  width: 100%;
  margin: 0;
  background-image: url(/images/background.jpg);
}

.jumbotron {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 400px;
  padding: 50px;
  text-align: left;
  background-color: #ffffff88;
  border-radius: 25px;
}

.brand {
  font-family: 'Abel';
}

.jumbotron .brand {
  font-size: 3.5rem;
}

.jumbotron .brand-com {
  font-size: 3rem;
}

.jumbotron p {
  font-size: 2rem;
}

.brand-virtual {
  color: #336e74;
}
.brand-pastries {
  color: #b5604c;
}
.brand-com {
  color: #a5abab;
}

